import React from 'react'
import 'react-dom'
import { createRoot } from 'react-dom/client'
import { App, AlexTheme, ConnectedResume } from '@ossy-se/components-react'

const container = document.getElementById('root')
const root = createRoot(container)

const routes = [
  {
    path: '/',
    element: <ConnectedResume path="/en/resume/"/>
  }
]

root.render(
  <App
    workspaceId="x86lBDscU2SeEUxHPFxKh"
    theme={AlexTheme}
    routes={routes}
  />
)
